import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Span } from 'components/'
import DigiPayaman from 'images/digipayaman.png'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promo/Digipayaman`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'DIGIPAYAMAN AGENTS OF THE MONTH (November 1-30, 2020)',
  },
  {
    property: 'og:description',
    content:
      'Transact DigiPadala at magkaroon ng chance manalo ng up to 5,000 Digipay Credits!',
  },
  {
    property: 'og:image',
    content: DigiPayaman,
  },
]

const Promo4 = () => (
  <div>
    <Helmet meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <Image src={DigiPayaman} width="100%" align="center" />
    <div className="promo-page">
      {/* <h1 align="center" className="font-weight-bold"><Span color="#eb3236">DIGIPAYAMAN AGENTS OF THE MONTH</Span></h1>
      <h2 align="center">November 1 - 30, 2020</h2> */}
      <h4>PAANO SUMALI</h4>
      <ul>
        <li>
          Para mag-qualify, dapat ay isa kang registered Digipay Agent at may
          DigiPadala sa iyong Digipay Account.
          <ul>
            <li>Para magkaroon ng DigiPadala, i-submit ang mga sumusunod:</li>
            <ul>
              <li>DTI or SEC Registration</li>
              <li>Mayor’s or Barangay Permit</li>
              <li>
                Photos of the physical store (at least 3 clear photos, including
                the street view)
              </li>
            </ul>
          </ul>
        </li>
        <li>
          Ang top three (3) agents na may pinakamataas na number of transactions
          at may minimum na sampung (10) transactions sa loob ng isang buwan ay
          mananalo ng mga sumusunod:
          <ul>
            <li>1st - 5,000 Digipay Credits</li>
            <li>2nd - 3,000 Digipay Credits</li>
            <li>3rd - 1,000 Digipay Credits</li>
          </ul>
        </li>
        <li>
          Ang top three (3) agents na may pinakamataas na transaction value
          (amount) at may minimum na sampung libong (P10,000) transaction amount
          sa loob ng isang buwan ay mananalo ng mga sumusunod:
          <ul>
            <li>1st - 5,000 Digipay Credits</li>
            <li>2nd - 3,000 Digipay Credits</li>
            <li>3rd - 1,000 Digipay Credits</li>
          </ul>
        </li>
        <li>Ang cut-off ng transactions ay sa November 30, 2020 ng 11:59PM.</li>
        <li>Ang mga winners ay ia-announce sa December 2, 2020.</li>
      </ul>
      <hr />
      <h4>PAANO MAKUHA ANG PREMYO</h4>
      <ul>
        <li>
          Ike-credit ng Digipay ang mga prizes sa accounts ng agents bilang
          Digipay Credits.
        </li>
        <li>
          Ang crediting ng prizes ay maaaring tumagal ng five (5) business days
          pagkatapos mai-announce ang winners.
        </li>
      </ul>
    </div>
    <Footer />
  </div>
)
export default Promo4
